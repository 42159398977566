<template>
  <div class="reeedom-page">
    <!-- 总质押流动性 -->
    <TotalTvl />
    <div class="reedom-wrap">
      <template v-if="loadingFlag">
        <RedeemList
          v-if="true"
          :recordList="recordList"
          @handleToWithdraw="handleToWithdraw"
          @handleToScan="handleToScan"
          @getUsdtMebAmount="getUsdtMebAmount"
        ></RedeemList>
        <van-pull-refresh
          v-else
          v-model="refreshing"
          @refresh="onRefresh"
          loading-text=" "
          loosing-text=" "
          pulling-text=" "
          class="refresh"
        >
          <van-list
            v-model="isLoading"
            :offset="0"
            loading-text=" "
            :finished="finished"
            @load="onReachBottom"
          >
            <RedeemListApi
              :recordList="recordList"
              @handleToWithdraw="handleToWithdraw"
              @handleToScan="handleToScan"
              @getUsdtMebAmount="getUsdtMebAmount"
            >
            </RedeemListApi>
          </van-list>
        </van-pull-refresh>
        <NoneData v-if="!recordList || recordList.length < 1" />
      </template>
      <div class="loading-wrap" v-else>
        <VanLoading color="#66EAB9" vertical />
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config/app.config'
import stakeLock from '@/utils/stakeLock' // 锁仓合约
import pancakeObj from '../../../utils/pancakeRouter'
import TotalTvl from '../components/TotalTvl.vue' // 总流动性质押
import RedeemList from './components/RedeemList.vue'
import RedeemListApi from './components/RedeemListApi .vue'
import NoneData from '../../../components/common/NoneData.vue'
import { queryStakeList, queryRedeem } from '@/services/lpStake'
import { mapState } from 'vuex'

export default {
  name: 'Redeem',
  components: { TotalTvl, RedeemList, NoneData, RedeemListApi },
  data () {
    return {
      loadingFlag: false, // 列表获取标识
      mebPrice: 0, // meb的价值
      lpPrice: 0, // lp的价值
      recordList: [],
      finished: true, // 加载结束
      refreshing: false, // 下拉刷新状态
      isLoading: false, // 下拉刷新状态
      params: {
        pageNumber: 1,
        pageSize: 15,
        status: 0
      }
    }
  },
  computed: {
    ...mapState(['myAcount']),
    staking () {
      return this.$t('staking')
    },
    Waiting () {
      return this.$t('common.Waiting')
    },
    stakeTips () {
      return this.$t('common.stakeTips')
    }
  },
  methods: {
    async init () {
      await this.getLpPrice()
      this.getMebPrice()
      this.getRecordList() // 获取合约
      // this.getRecordListQuery() // 获取质押记录
    },
    onRefresh () {
      this.loadingFlag = false
      this.params.pageNumber = 1
      this.getRecordListQuery()
      this.refreshing = false
      this.loading = false
    },
    onReachBottom () {
      this.loadingFlag = false
      this.isLoading = true
      this.params.pageNumber++
      // this.getRecordList()
      this.getRecordListQuery()
    },
    // 获取质押记录
    async getRecordListQuery () {
      const resp = await queryStakeList(this.params)
      this.isLoading = false
      this.loadingFlag = true
      if (!resp.success) {
        this.finished = true
        return
      }
      if (this.params.pageNumber >= resp.result.lpContractPage.pages) {
        this.finished = true
      } else {
        this.finished = false
      }
      const currentBlock = await this.$web3.getBlock()
      var recordList = (resp.result.lpContractPage && resp.result.lpContractPage.records) || []
      recordList = recordList.map((item) => {
        item.userDepositAmount = item.amount
        item.createTime = (item.createTime || '').split(' ')[0]
        item.day = `${item.day}${this.staking.day}`
        item.lpTotalValue = this.$gbUtils.formatTwoBalance(
          item.amount * this.lpPrice
        )
        item.status = item.status.toString()
        item.poolId = parseInt(item.poolId)
        item.recordId = parseInt(item.recordIndex)
        // 已到期待赎回的时候，新增判断到期区块与当前区块比较，避免数据库到期时间与链上时间不一致的情况
        if (item.status === '2' && (item.endBlockNumber > currentBlock)) {
          item.status = '1'
        }
        if (item.status === '1' && item.endBlockNumber && (item.endBlockNumber > currentBlock)) {
          const unlockTime = parseInt(item.endBlockNumber) - currentBlock
          if (unlockTime > 28800) {
            item.unlockTime = `${(unlockTime / 28800).toFixed(0)}${
                this.staking.day
              }`
          } else if (unlockTime > 1200) {
            item.unlockTime = `${(unlockTime / 1200).toFixed(0)}${
                this.staking.hour
              }`
          } else if (unlockTime > 20) {
            item.unlockTime = `${(unlockTime / 20).toFixed(0)}${
                this.staking.minute
              }`
          } else {
            item.unlockTime = `${unlockTime.toFixed(0)}${
                this.staking.second
              }*3`
          }
        } else {
          item.unlockTime = 0 + this.staking.day
        }
        return item
      })
      this.recordList =
        this.params.pageNumber === 1
          ? recordList
          : this.recordList.concat(recordList)
    },
    // 格式化 unit256转为十进制
    fromWei (val) {
      if (!val) return 0
      return this.$web3.fromWei(val)
    },
    // 获取lp价值
    async getLpPrice () {
      const lpPriceResp = await stakeLock.getLpPrice(this.poolId || 0)
      if (lpPriceResp.success) {
        this.lpPrice = this.$gbUtils.formatBalance(lpPriceResp.result)
      }
    },
    // 获取meb的单价
    async getMebPrice () {
      const resp = await pancakeObj.getAmountsIn(1)
      if (!resp.success) return
      this.mebPrice = resp.result
      console.log('mebPrice:', this.mebPrice)
    },
    // 获取质押池
    async getPools () {
      const resp = await stakeLock.getPools()
      console.log('质押池', resp)
      this.poolList = resp.result
    },
    // 获取质押记录
    async getRecordList () {
      this.loadingFlag = false
      this.recordList = []
      const currentBlock = await this.$web3.getBlock()
      await this.getPools()
      var id = 0
      for (let i = 0; i < this.poolList.length; i++) {
        const resp = await stakeLock.lockRecordList(i)
        const recordList = (resp.result || []).map((item, index) => {
          const temp = Object.assign({ ...item })
          temp.poolId = i
          temp.recordId = index
          temp.id = id + 1
          temp.lpShow = false
          temp.userDepositAmount = this.fromWei(temp.userDepositAmount)
          temp.nowlpPrice = this.fromWei(temp.nowlpPrice)
          temp.lpTotalValue = temp.userDepositAmount * temp.nowlpPrice
          if (currentBlock > 0 && temp.endBlockNumber > currentBlock) {
            temp.operateType = '3'
            const unlockTime = temp.endBlockNumber - currentBlock
            if (unlockTime > 28800) {
              temp.unlockTime = `${(unlockTime / 28800).toFixed(0)}${
                this.staking.day
              }`
            } else if (unlockTime > 1200) {
              temp.unlockTime = `${(unlockTime / 1200).toFixed(0)}${
                this.staking.hour
              }`
            } else if (unlockTime > 20) {
              temp.unlockTime = `${(unlockTime / 20).toFixed(0)}${
                this.staking.minute
              }`
            } else {
              temp.unlockTime = `${unlockTime.toFixed(0)}${
                this.staking.second
              }*3`
            }
          } else {
            temp.unlockTime = 0
          }
          return temp
        })
        this.recordList =
          i === 0 ? recordList : this.recordList.concat(recordList)
      }
      this.loadingFlag = true
    },
    // 获取对应lp数量对应的两个币的量
    getUsdtMebAmount (lpcount, index) {
      const params = this.$gbUtils.getUsdtMebAmount(
        lpcount,
        this.lpPrice,
        this.mebPrice
      )
      this.recordList[index].usdt = params.usdt
      this.recordList[index].meb = params.meb
    },
    // 查看链上数据
    async handleToScan (item) {
      this.$emit('changeLoading', true)
      this.$emit('changeLoadingIconType', 2)
      const resp = await stakeLock.getPastEvents(item.startBlockNumber)
      this.$emit('changeLoading', false)
      if (resp.success) {
        window.open(`${config.bscBrowser}/tx/${resp.result}`)
      } else {
        window.open(`${config.bscBrowser}/address/${this.myAcount}`)
      }
    },
    // 赎回
    async handleToWithdraw (item) {
      const poolId = item.poolId
      const recordId = item.recordId
      const startBlockNumber = item.startBlockNumber
      const message = this.Waiting + ' ' + this.stakeTips.Redeem
      this.$emit('changeLoading', true)
      this.$emit('changeLoadingIconType', 2)
      this.$emit('setLoadingMessage', message)
      const resp = await stakeLock.withdraw(poolId, recordId)
      this.$emit('changeLoading', false)
      if (resp.success) {
        const params = {
          poolId: poolId,
          startBlockNumber: startBlockNumber
        }
        this.redeemLP(params) // 赎回
        this.getRecordList()
        // this.getRecordListQuery() // 刷新
      } else return this.$toast('fail')
    },
    // 赎回LP java
    async redeemLP (params) {
      const resp = await queryRedeem(params)
      if (resp.success) return this.$toast('Success')
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.reeedom-page {
  padding: 0 13px 10px 13px;
  box-sizing: border-box;
}
.reedom-wrap {
  width: 100%;
  min-height: 450px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  margin-top: 24px;
  overflow: hidden;
  .loading-wrap {
    padding: 150px 0;
    box-sizing: border-box;
  }
}
</style>
