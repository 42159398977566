<template>
  <div class="reedom-list">
    <div
      class="reedom-item bottom-line"
      v-for="(item, index) in recordList"
      :key="index"
    >
      <!-- 质押数据 -->
      <div class="reedom-box">
        <!-- 质押lp数量 -->
        <div class="left">
          <div class="top">
            <div class="lp-icons">
              <SvgIcon iconClass="USDT" class="usdt-icon"></SvgIcon>
              <SvgIcon iconClass="MEB" class="med-icon"></SvgIcon>
            </div>
            <div class="stake-amount">
              <div>{{ staking.amount }}</div>
              <div class="stake-amount-lp">
                <span>{{ item.amount }}</span>
                <!-- <div class="usdt-price">~ {{ item.lpTotalValue }} USD</div> -->
              </div>
            </div>
            <!-- 查看链上数据 -->
          </div>
          <!-- 数量 -->
          <div class="amount-box">
            <div>{{ getUsdtMebAmount(item, index) }}</div>
            <!-- <p>{{$gbUtils.formatBalance(item.lpTotalValue)}} USDT</p> -->
            <div v-if="item.usdt || item.meb">
              {{ item.usdt || 0 }} USDT + {{ item.meb || 0 }} MEB
            </div>
          </div>
        </div>
        <!-- lp质押状态等信息 -->
        <div class="right">
          <div class="left-list">
            <div class="lp-item" v-if="false">
              <div class="title">{{ staking.StakeTime }}</div>
              <div class="amount">{{ item.createTime }}</div>
            </div>
            <div class="lp-item">
              <div class="title">{{ staking.unlock }}</div>
              <div class="amount">{{ item.unlockTime }}</div>
            </div>
            <!-- 状态 -->
            <div class="lp-item">
              <div class="title">{{ staking.state }}</div>
              <div>
                <!-- 已到期 -->
                <!-- <span
                class="reedom-btn"
                v-if="item.operateType === '1'"
                @click.stop="handleToWithdraw(item)"
                >{{ staking.unstaked }}</span
              > -->
                <span
                  v-if="item.status === '2'"
                  class="reedom-btn"
                  @click.stop="handleToWithdraw(item)"
                >
                  {{ staking.unstaked }}
                  <!-- {{ staking.Expired }} -->
                </span>
                <!-- 已赎回 -->
                <span
                  v-if="item.status === '3'"
                  class="amount"
                  style="color: #c1c1c1"
                  >{{ staking.alreadyRedeem }}</span
                >
                <!-- 未到期 -->
                <span
                  v-if="item.status === '1'"
                  class="amount"
                  style="color: #ed8080"
                  >{{ staking.unredeem }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="check-online" @click.stop="handleToScan(item)">
          <div class="iconfont icon-view"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import SvgIcon from '@/components/SvgIcon.vue'

export default {
  components: { SvgIcon },
  props: {
    recordList: {
      default () {
        return []
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(['myAcount']),
    staking () {
      return this.$t('staking')
    },
    detail () {
      return this.$t('common.detail')
    }
  },
  methods: {
    // 格式化 unit256转为十进制
    fromWei (val) {
      return this.$web3.fromWei(val)
    },
    // 提现
    handleToWithdraw (item) {
      this.$emit('handleToWithdraw', item)
    },
    // 查看链上交易
    handleToScan (item) {
      this.$emit('handleToScan', item)
    },
    handleBank () {
      return false
    },
    getUsdtMebAmount (item, index) {
      this.$emit('getUsdtMebAmount', item.userDepositAmount, index)
    }
  }
}
</script>
<style lang="scss" scoped>
.reedom-list {
  width: 100%;
  .reedom-item {
    box-sizing: border-box;
    .left {
      width: 60%;
    }
    .reedom-box {
      display: flex;
      padding: 20px 25px 10px 25px;
      box-sizing: border-box;
      min-height: 100px;
      background: #fff;
      overflow: hidden;
      .right {
        width: 120px;
      }
      .left-list {
        display: flex;
        width: 100%;
        padding-top: 10px;
        box-sizing: border-box;
        justify-content: space-between;
        .lp-item {
          flex: 1;
          font-size: 12px;
          color: #16172a;
          .title {
            font-size: 10px;
            color: #818086;
            margin-bottom: 5px;
          }
          .amount {
            font-weight: bold;
            height: 28px;
            line-height: 28px;
          }
        }
      }
      .top {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .lp-icons {
          margin-right: 10px;
          .svg-icon {
            display: block;
          }
          .usdt-icon {
            width: 16px;
            height: 16px;
          }
          .med-icon {
            width: 25px;
            height: 25px;
            margin-left: 6px;
            margin-top: -8px;
          }
        }
        .stake-amount {
          color: #977ff1;
          font-size: 10px;
          .stake-amount-lp {
            color: #16172a;
            font-weight: bold;
            font-size: 18px;
            .usdt-price {
              font-size: 9px;
              font-weight: bold;
              color: #818086;
            }
          }
        }
      }
      .bottom {
        position: relative;
      }
    }
    &.bottom-line {
      border-bottom: 1px solid #f0f0f0;
    }
    .check-online {
      color: #24c294;
      font-size: 12px;
      align-items: center;
      flex: 1;
      text-align: right;
      .iconfont {
        font-size: 14px;
        margin-left: 5px;
        display: block;
      }
    }
    .amount-box {
      color: #818086;
      font-size: 10px;
    }
    .reedom-btn {
      display: inline-block;
      padding: 0 8px;
      height: 28px;
      line-height: 28px;
      background: #24c294;
      border-radius: 6px;
      color: #fff;
      text-align: center;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
  :last-child {
    border-bottom: 1px solid #fff !important;
  }
}
.lp-box {
  width: 100%;
  height: 150px;
  background: #f9f9f9;
  padding: 20px;
  box-sizing: border-box;
  .lp-amount-box {
    height: 80px;
    border: 1px solid #c1c1c1;
    border-radius: 10px;
    padding: 10px 25px 10px 15px;
    box-sizing: border-box;
    margin-bottom: 15px;
    .title {
      color: #977ff1;
      font-size: 10px;
      font-weight: bold;
      line-height: 20px;
    }
    .amount-box {
      :first-child {
        color: #16172a;
        font-size: 18px;
        font-weight: bold;
      }
      :last-child {
        color: #818086;
        font-size: 10px;
      }
    }
    .event-state {
      font-size: 12px;
      .reedom-btn {
        display: block;
        padding: 0 23px;
        height: 35px;
        line-height: 35px;
        background: #24c294;
        border-radius: 10px;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }
  ._flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .check-online {
    color: #24c294;
    font-size: 12px;
    display: flex;
    align-items: center;
    .iconfont {
      font-size: 14px;
      margin-left: 5px;
      display: block;
    }
  }
}
// .collapse-enter-active,
// .collapse-leave-active {
//   transition: all .2s ease;
// }
.collapse-enter,
.collapse-leave-to {
  height: 0;
  overflow: hidden;
}
</style>
